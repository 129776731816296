import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';


class DebtsReportService {
    readDebtsReportQuery(){
        var query = `
            query ($startDate: DateTime!, $endDate: DateTime!, $id: Int, $status: String, $cutOff: DateTime) {
                GetReportingDebts(start_date:$startDate, end_date:$endDate, supplier_id:$id, status:$status, cut_off:$cutOff) {
                    invoice_id
                    invoice_date
                    supplier_id
                    supplier_name
                    currency_code
                    invoice_due_date
                    due_day
                    supplier_invoice_number
                    tax_invoice
                    total
                    paid
                    outstanding_amount
                    status
                    last_payment
                } 
            }`;
        return query;
    }
    
    async getDebtsReportQuery(variables){
        var query = `
            query ($startDate: DateTime!, $endDate: DateTime!, $id: Int, $status: String, $cutOff: DateTime) {
                GetReportingDebts(start_date:$startDate, end_date:$endDate, supplier_id:$id, status:$status, cut_off:$cutOff) {
                    invoice_id
                    invoice_date
                    supplier_id
                    supplier_name
                    currency_code
                    invoice_due_date
                    due_day
                    supplier_invoice_number
                    tax_invoice
                    total
                    paid
                    outstanding_amount
                    status
                    last_payment
                } 
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetReportingDebts;
    }

    async getSupplierQuery(){
        var query = gql`
            query {
                GetContact (ContactType:"Vendor") {
                    contact_id
                    contact_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { 
                    value: result.data.GetContact[i].contact_id, 
                    label: result.data.GetContact[i].contact_name
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    debtReportExcelHeaderSize(){
        var size = [
            {width: 40}, //colA
            {width: 25}, //colB
            {width: 20}, //colC
            {width: 30}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
        ];

        return size;
    }

    debtReportExcel(data, variables){
        const uniques = [];
        data.forEach((t) => !uniques.includes(t.supplier_id) && uniques.push(t.supplier_id));

        var arrayObject = [];
        var date = 'Per ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)';

        var startIndex = 5;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'HUTANG DAGANG',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        if(data != null){
            for (let k= 0; k < uniques.length; k++) {
                var row5 = {
                    colA : 'Supplier',
                    colB : 'Tgl Jatuh Tempo Invoice',
                    colC : 'Tgl Jatuh Tempo',
                    colD : 'No. Bukti',
                    colE : 'No. Faktur Pajak',
                    colF : 'Tgl Invoice',
                    colG : 'Tgl Pembayaran',
                    colH : 'Mata Uang',
                    colI : 'Nilai Invoice',
                    colJ : 'Outstanding'
                };
                arrayObject.push(row5);

                var detail = data.filter(c => c.supplier_id == uniques[k])
                var total_outstanding = 0;
                for (let i = 0; i < detail.length; i++){
                    var row = {
                        colA : detail[i].supplier_name,
                        colB : detail[i].invoice_due_date != null ? (detail[i].invoice_due_date != '-' ? { v: kendo.toString(kendo.parseDate(new Date(detail[i].invoice_due_date).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd'), t: 'd', z: 'DD-MMM-YYYY' } : '') : '',
                        colC : { v: detail[i].due_day, t: 'n' , z: numberFormat },
                        colD : detail[i].supplier_invoice_number,
                        colE : detail[i].tax_invoice,
                        colF : detail[i].invoice_date != null ? (detail[i].invoice_date != '-' ? { v: kendo.toString(kendo.parseDate(new Date(detail[i].invoice_date).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd'), t: 'd', z: 'DD-MMM-YYYY' } : '') : '',
                        colG : detail[i].last_payment != null ? (detail[i].last_payment != '-' ? { v: kendo.toString(kendo.parseDate(new Date(detail[i].last_payment).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd'), t: 'd', z: 'DD-MMM-YYYY' } : '') : '',
                        colH : detail[i].currency_code,
                        colI : { v: detail[i].total, t: 'n' , z: numberFormat },
                        colJ : { v: detail[i].outstanding_amount, t: 'n' , z: numberFormat },
                    };
                    arrayObject.push(row);

                    total_outstanding += detail[i].outstanding_amount;
                }

                var supplierData = data.find(c => c.supplier_id == uniques[k])
                var row = { 
                    colA : 'Total Per ' + supplierData.supplier_name,
                    colB : '',
                    colC : '',
                    colD : '',
                    colE : '',
                    colF : '',
                    colG : '',
                    colH : '',
                    colI : '',
                    colJ : {v: total_outstanding , t: 'n', z: numberFormat},
                };
                arrayObject.push(row);
                        
                var spaceRow = {colA : null};
                arrayObject.push(spaceRow);
            }
        }

        return arrayObject;
    }
}


export default new DebtsReportService();