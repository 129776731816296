<template>
    <div>
        <kendo-grid
            ref="grid"
            :data-source="DebtReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
    </div>
</template>
  
<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';
import DebtReportServices from '../Script/DebtsReportService.js';
  
export default {
    name: 'DebtReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dateTo', 'dateFrom', 'supplier', 'status', 'cutOffDate'],
    computed: {
        DebtReport () {
            var dateFrom = this.$props.dateFrom;
            var dateTo = this.$props.dateTo;
            var supplierData = this.$props.supplier;
            var statusData = this.$props.status;
            var cutOffDate = this.$props.cutOffDate;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                                cutOff: cutOffDate,
                                id: (supplierData != null ? supplierData.value : supplierData),
                                status: (statusData != null ? statusData.value : statusData),
                            };
                            return {
                                query: DebtReportServices.readDebtsReportQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetReportingDebts == null) {
                            return [];
                        }
                        else {
                            return response.data.GetReportingDebts;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetReportingDebts == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetReportingDebts.length;
                        }
                    },
                    model: {
                        fields: {
                            due_date: {type: "date"},
                            due_day: {type: "number" },
                            outstanding_amount: {type: "number" },
                            invoice_due_date: {type: "date"},
                            invoice_date: {type: "date"},
                            last_payment: {type: "date"},
                        }
                    }
                },
                group: [
                    {field: "supplier_name"}
                ]
            });
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "supplier_name", title: "Supplier", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "invoice_due_date", title: "Tanggal Jatuh Tempo Invoice", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= invoice_due_date != null ? (invoice_due_date != '-' ? kendo.toString(kendo.parseDate(new Date(invoice_due_date).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') : '') : '' #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "due_day", title: "Tanggal Jatuh Tempo", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "supplier_invoice_number", title: "No. Bukti", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "tax_invoice", title: "No. Faktur Pajak", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "invoice_date", title: "Tanggal Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= invoice_date != null ? (invoice_date != '-' ? kendo.toString(kendo.parseDate(new Date(invoice_date).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') : '') : '' #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "last_payment", title: "Tanggal Pembayaran", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= last_payment != null ? (last_payment != '-' ? kendo.toString(kendo.parseDate(new Date(last_payment).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') : '') : '' #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "currency_code", title: "Mata Uang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "total", title: "Nilai Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                    ,format:"{0:N0}" },
                { field: "outstanding_amount", title: "Outstanding", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                    ,format:"{0:N0}" },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
    },
    methods: {
        async exportExcel(DateFrom, DateTo, CutOffDate) {
            const variables = {
                startDate: DateFrom,
                endDate: DateTo,
                cutOffDate: CutOffDate
            };

            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await DebtReportServices.debtReportExcel(gridData, variables);
            if (gridData == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_Hutang_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = DebtReportServices.debtReportExcelHeaderSize();

                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
        },
    },
}
</script>